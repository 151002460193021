import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/KINGDOM-KIDS.png'
import Cta from '../images/new/banners/KINGDOM-KIDS-2.png'

import { 
  section,
  HeaderBanner,
  container,
  content
} from '../components/styles/Base.styles'

const BasePage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="KINGDOM - | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]}>
      <div css={container}>
        <h1>Kingdom Kids</h1>
        <p>A Kingdom Kids tem a estimulação como premissa e, se tratando de criança pequena, é bastante importante que isso seja feito da forma mais leve e prazerosa.</p>
        <p>Levando em consideração que a criança pequena tem um tempo de concentração limitado, nossas atividades são feitas em ciclos de 30 em 30 minutos. Portanto, no período de 4 horas, a turma passa por 8 ciclos que se dividem em duas atividades extras (psicomotricidade, espanhol ou música), inglês, práticas pedagógicas, parque e refeição.</p>
        <img src={Cta} alt='' />
        <p>Além destas atividades, os alunos têm práticas esportivas como: xadrez, dança e capoeira. Todas as atividades extras são ministradas por professores específicos e especializados na determinada modalidade.</p>
        <p>Na Kingdom Kids, as turmas têm um número de alunos reduzido, tendo as turmas em média 16 crianças por sala, 1 professora e, no mínimo, 2 auxiliares. Essa estrutura reduzida nos permite oferecer um serviço de qualidade ao ter uma escola mais personalizada no que se refere à individualidade de cada um.</p>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BasePage
